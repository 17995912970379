import { post, get } from '../service.js'
import qs from 'qs'

export const addSubjectApi = (name) => {
  return post({
    url: `/subject/add?name=${name}`
  })
}

export const delSubjectApi = (subjectId) => {}

export const editSubjectApi = (id, name) => {
  return post({
    url: `/subject/name/update?id=${id}&name=${name}`
  })
}

export const getSubjectListApi = () => {
  return get({
    url: '/subject/list'
  })
}

export const getSubjectInfoApi = (subjectId) => {
  return get({
    url: `/subject/chapter/get?id=${subjectId}`
  })
}

export const addChapterApi = (subjectId, number, title) => {
  return post({
    url: '/subject/chapter/add',
    data: { subjectId, number, title }
  })
}

export const delChapterApi = (chapterId) => {
  return get({
    url: '/subject/chapter/del',
    data: { chapterId }
  })
}

export const editChapterApi = (chapterId, number, title) => {
  return post({
    url: '/subject/chapter/update',
    data: { chapterId, number, title }
  })
}

export const addSectionApi = (chapterId, number, title) => {
  return post({
    url: '/subject/section/add',
    data: { chapterId, number, title }
  })
}

export const delSectionApi = (sectionId) => {
  return get({
    url: '/subject/section/del',
    data: { sectionId }
  })
}

export const editSectionApi = (sectionId, number, title) => {
  return post({
    url: '/subject/section/update',
    data: { sectionId, number, title }
  })
}

export const addPointApi = (subjectId, content, chapterId) => {
  return get({
    url: '/subject/point/add',
    data: { subjectId, content, chapterId }
  })
}

export const delPointApi = (pointId, subjectId) => {
  return get({
    url: '/subject/point/del',
    data: {
      id: pointId,
      subjectId
    }
  })
}

export const editPointApi = (pointId, content) => {
  return get({
    url: '/subject/point/update',
    data: {
      id: pointId,
      content
    }
  })
}

export const getPointListApi = (chapterId, content, current, pageSize) => {
  var data = {
    content,
    current,
    pageSize
  }
  if (chapterId) data.chapterId = chapterId[1]
  return get({
    url: '/subject/point/list',

    data: data
  })
}

export const getSectionQuestionListApi = (sectionId) => {
  return get({
    url: '/inserted/question/section/question/all',
    data: { sectionId }
  })
}
