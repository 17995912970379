<template>
  <div class="container">首页</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
  name: 'home',

  setup() {
    const data = reactive({})

    return {
      ...toRefs(data)
    }
  }
}
</script>
<style lang="less" scoped></style>
